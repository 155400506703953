<template>
  <button v-ripple @click="onClickSound" :class="{ snooze: true, disabled: !sound, dark: $vuetify.theme.dark }">
    <img v-if="!sound" src="@/assets/Snooze-On_Line_1_32px_Icon.svg" width="24px" />
    <img v-if="sound && $vuetify.theme.dark" src="@/assets/Snooze-On_Line_1_32px_Icon-white.svg" width="24px" />
    <img v-else-if="sound" src="@/assets/Snooze-On_Line_1_32px_Icon-white2.svg" width="24px" />
    <div style="margin-left: 8px">Snooze</div>
  </button>
</template>

<script>
export default {
  props: {
    sound: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onClickSound() {
      this.$emit('change', false)
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  height: 44px;
  color: var(--f-text-white);
  border-radius: 8px;
  width: 120px;
  font-weight: 600;
  font-size: 16px;
  background-color: var(--f-primary-50);
  &.dark {
    background-color: var(--f-bg-high);
  }

  &.disabled {
    background-color: #0000001f;
    color: #00000042;
    &.dark {
      color: #00000042;
      background-color: var(--f-light-gray-2);
    }
    &:active {
      opacity: 1;
    }
  }

  &.snooze {
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: none;
  }
}
</style>
