<template>
  <div class="alert-effect">
    <div v-if="!stoped" class="frame"></div>
    <!-- <div v-if="!stoped" class="alert-effect-background" :style="blinkStyle"></div> -->
    <div v-if="!stoped" class="alert-box" :style="blinkStyle">Smoke Detected</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      SOUND_PLAY_DURATION: 12000,
      WAIT_BLINK: 9000,
      BLINK_TIME: 2000,
      BLINK_MAX_COUNT: 6,
      EFFECT_COUNT: 3,
      timerBlink: null,
      timerWait: null,
      timerSound: null,
      blinkFlag: false,
      blinkCount: 0,
      alertEffectCount: 0,
      stoped: false,
    }
  },
  computed: {
    blinkStyle() {
      return !this.blinkFlag ? 'visibility: visible' : 'visibility: hidden'
    },
  },
  mounted() {
    this.start()
  },
  destroyed() {
    this.stop()
    clearTimeout(this.timerSound)
  },
  methods: {
    start() {
      this.stop()
      this.stoped = false
      // start alert frame
      this.timerWait = setTimeout(() => {
        this.blinkFlag = true
        // start alert information
        this.startBlink()
      }, this.WAIT_BLINK)
      // stop sound timer
      clearTimeout(this.timerSound)
      this.timerSound = setTimeout(() => {
        this.$emit('stop')
      }, this.SOUND_PLAY_DURATION)
    },
    stop() {
      this.blinkCount = 0
      clearTimeout(this.timerBlink)
      clearTimeout(this.timerWait)
    },
    startBlink() {
      clearTimeout(this.timerBlink)
      this.timerBlink = setTimeout(() => {
        this.blinkCount++
        if (this.blinkCount === this.BLINK_MAX_COUNT) {
          this.stop()
          this.stoped = true
          this.alertEffectCount++
          this.$log.debug('AlertEffect#startBlink stop count', this.alertEffectCount)
          if (this.alertEffectCount < this.EFFECT_COUNT) {
            this.start()
          } else {
            this.$emit('finish')
          }
        } else {
          this.blinkFlag = !this.blinkFlag
          this.startBlink()
        }
      }, this.BLINK_TIME)
    },
  },
}
</script>

<style lang="scss" scoped>
.alert-effect {
  position: fixed;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 3;
  pointer-events: none;

  .frame {
    position: absolute;
    text-align: center;
    border: 15px solid var(--f-primary-100);
    width: 100%;
    height: 100%;
  }

  .alert-effect-background {
    position: absolute;
    background-color: #ff3b30;
    opacity: 0.2;
    width: 100%;
    height: 100%;
  }

  .alert-box {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--f-primary-90);
    border: 5px solid var(--f-primary-50);
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    color: white;
    font-weight: 600;
    font-size: 30px;
    width: 360px;
    height: 90px;
    margin-top: 30px;
    transform: translateX(-180px);
  }
}
</style>
