<template>
  <header :class="{ 'background-toolbar': true, dark: $vuetify.theme.dark }">
    <div class="d-flex align-center">
      <img v-if="$vuetify.theme.dark" @click="onClickExit" src="@/assets/FireScoutLogo_white.svg" width="140px" class="icon-logo" />
      <img v-else @click="onClickExit" src="@/assets/FireScoutLogo_color.svg" width="140px" class="icon-logo" />
      <div class="text-title">{{ user.user_name }}</div>
    </div>
    <div class="spacer-1"></div>
    <div class="text-date">{{ date }}</div>
    <div class="text-time">{{ time }}</div>
    <div class="spacer-1"></div>
    <ButtonSnooze :sound="sound && newLog" @change="$emit('sound', $event)" style="margin-right: 15px"></ButtonSnooze>
    <button v-ripple @click="onClickExit" class="exit">Exit</button>
  </header>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import ButtonSnooze from './ButtonSnooze'
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  components: { ButtonSnooze },
  props: {
    sound: {
      type: Boolean,
      required: true,
    },
    newLog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      date: '',
      time: '',
      timeoutObj: null,
    }
  },
  computed: {
    ...mapState(['camList', 'user']),
    organization() {
      return this.user.organization_name
    },
    name() {
      return this.user.user_name
    },
  },
  created() {
    this.startTimer()
  },
  beforeDestroy() {
    clearTimeout(this.timeoutObj)
  },
  methods: {
    onClickExit() {
      this.$router.push(`/`)
    },
    startTimer() {
      let oneSecond = 1000
      let remain = oneSecond - (Date.now() % oneSecond)
      if (remain === 0) {
        remain = oneSecond
      }
      let date = moment()
      this.date = date.format('YYYY-MM-DD')
      this.time = date.format('HH:mm:ss')
      this.timeoutObj = setTimeout(() => {
        this.startTimer()
      }, remain)
    },
  },
}
</script>

<style lang="scss" scoped>
.background-toolbar {
  position: fixed;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 74px;
  z-index: 2;
  color: var(--f-text-black);
  border-bottom: 1px solid var(--f-light-gray-1);
  &.dark {
    color: var(--f-text-white-high);
    border-bottom-color: var(--f-bg-high);
  }

  .icon-logo {
    cursor: pointer;
    margin-left: 30px;
    margin-right: 10px;
  }
}

.text-title {
  font-weight: 500;
  font-size: 14px;
  color: var(--f-primary-100);
}

.text-date {
  font-weight: 600;
  font-size: 20px;
  margin-right: 15px;
}

.text-time {
  font-weight: 600;
  font-size: 30px;
}

button {
  height: 44px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
  min-width: 108px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.exit {
    color: var(--f-text-white-high);
    background-color: var(--f-primary-100);
    margin-right: 30px;
  }
}
</style>
