<template>
  <div class="background">
    <AlertEffect ref="alertEffect" v-if="newLog" @stop="onStopAlert" @finish="onChangeSound(false)"></AlertEffect>
    <audio ref="audio" src="@/assets/alert.wav" preload loop>Your browser does not support the audio element.</audio>
    <ToolbarMonitor :sound="isSound" :newLog="newLog" @sound="onChangeSound"></ToolbarMonitor>
    <main class="main">
      <div class="left-container">
        <div class="top-container">
          <div class="title1 detection-clip">
            Detection Clip
            <div v-if="providerLogoUrl" class="monitor-camera-provider">
              Cameras by
              <img :src="providerLogoUrl" width="113px" />
            </div>
          </div>
          <ImagePlayer
            :currentLog="currentLog"
            :monitor="true"
            @stop="onStopPlayer"
            :play="play"
            :speed="300"
            :drawBox="true"
            @loaded="imageLoaded"
            class="player"
          ></ImagePlayer>
        </div>
        <div class="bottom-container">
          <div class="title1">
            {{ fireLocationLabel }}
            <v-spacer></v-spacer>
            <div class="title1-map direction">{{ degreeString }}</div>
            <div class="title1-map">Direction may differ in the range of ±5˚.</div>
          </div>
          <div
            :class="{ 'image-container': true, map: true, 'background-black': !currentLog }"
            @mousewheel="onMousewheel"
            @keydown="onKeydown"
            @keyup="onKeyup"
          >
            <div v-if="isImageLoaded" :class="{ 'map-view-scroll-guide': true, visible: isInvalidScroll, hidden: !isInvalidScroll }">
              Use Ctrl + scroll to zoom the map
            </div>
            <Map
              v-if="isImageLoaded"
              :currentLog="currentLog"
              @cardinal-direction="degreeString = $event"
              :class="{ 'map-visible': currentLog && currentLog.direction !== null, 'map-invisible': !(currentLog && currentLog.direction !== null) }"
            ></Map>
            <Loading
              v-if="(currentLog && currentLog.direction === null) || !isImageLoaded"
              :loading="true"
              style="top: 0; border-radius: 15px"
            ></Loading>
          </div>
        </div>
      </div>
      <div class="right-container">
        <div class="top-container">
          <div class="inner-flex-container">
            <div :class="`info-container ${themeName}`">
              <div class="container left">
                <div class="text-title">Detection Time(PT)</div>
                <div class="text-selectable">{{ detectionTime }}</div>
              </div>
              <div :class="`line-vertical-center ${themeName}`"></div>
              <div class="container right">
                <div class="text-title">Camera Name</div>
                <div style="display: flex; align-items: center">
                  <div class="text-truncate text-selectable">{{ detectionName }}</div>
                  <img v-if="alertOff" src="@/assets/Alert_Off_SkyBlue_Icon.svg" width="24px" style="margin-left: 2px; flex-shrink: 0" />
                </div>
              </div>
            </div>
            <WeatherInfo
              :temperature="temperature"
              :windSpeed="windSpeed"
              :windDirection="windDirection"
              :relativeHumidity="relativeHumidity"
              :station="station"
              :class="`weather-container ${themeName}`"
            ></WeatherInfo>
            <div v-if="recentDisplayLogs.length" :class="`recent-container ${themeName}`">
              <div v-for="item in recentDisplayLogs.slice(0, 3)" :key="item.log_id" :class="`recent-item text-truncate ${themeName}`">
                <span class="time">{{ item.time }}</span
                ><span class="name">{{ item.name }}</span>
              </div>
            </div>
            <div v-else :class="`recent-container no-data ${themeName}`">No data available.</div>
          </div>
        </div>
        <div class="bottom-container">
          <div class="title1 livestream">Livestream</div>
          <div class="image-container right" :style="liveImageStyle"></div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ToolbarMonitor from '@desktop/adminOrViewer/monitor/ToolbarMonitor'
import AlertEffect from '@desktop/adminOrViewer/monitor/AlertEffect'
import ImagePlayer from '@common/ImagePlayer/ImagePlayer'
// import Map from '@common/GoogleMaps/Map'
import Map from '@common/ArcGIS/MonitorMap'
import Loading from '@common/ImagePlayer/Loading.vue'
import Util from '@/util'
import SelfInfoMixin from '@/mixin/selfinfo.mixin'
import Theme from '@/mixin/theme.mixin'
import AlertOff from '@/mixin/alertoff.mixin'
import WeatherInfo from '@desktop/adminOrViewer/monitor/WeatherInfo'

export default {
  mixins: [SelfInfoMixin, Theme, AlertOff],
  components: { ToolbarMonitor, AlertEffect, ImagePlayer, Map, Loading, WeatherInfo },
  data() {
    return {
      INTERVAL_SITE_LOG: 5000,
      detectionTime: '',
      detectionName: '',
      currentCamImageSrc: '',
      currentLog: null,
      play: 'stop',
      newLog: false,
      isSound: true,
      loading: true,
      degreeString: '',
      temperature: '-',
      windSpeed: '-',
      windDirection: '-',
      relativeHumidity: '-',
      station: '-',
      isImageLoaded: false,
      // zoom control restriction(s)
      isPressingControlKey: false,
      isInvalidScroll: false,
      invalidScrollTimeout: null,
      recentLogIdSet: new Set(),
    }
  },
  computed: {
    ...mapState(['camMap', 'camList', 'recentLogListv2']),
    providerLogoUrl() {
      if (this.currentLog && this.currentLog.provider_dark_logo_path) {
        return Util.getImageUrl(this.currentLog.provider_dark_logo_path)
      }

      return null
    },
    fireLocationLabel() {
      if (!this.currentLog) return 'Fire Location'
      const latitude = this.currentLog.latitude
      const longitude = this.currentLog.longitude
      const panDirection = this.currentLog.pan_direction
      if ((latitude === -1 || !latitude) && (longitude === -1 || !longitude) && panDirection !== -1 && panDirection) return 'Fire Direction'
      else return 'Fire Location'
    },
    recentLogs() {
      let logs = this.recentLogListv2.slice(0, 6).filter((log) => Util.checkRecent72(log.created_at, log.offset) && !log.is_checked_organization)
      if (logs.length) {
        return logs
      } else if (this.recentLogListv2.length) {
        return [this.recentLogListv2[0]]
      } else {
        return []
      }
    },
    recentDisplayLogs() {
      // this.$log.debug('Monitor', this.camMap)
      return this.recentLogs.map((log) => {
        return {
          id: log.log_id,
          time: `[${Util.getOffsetDate(log.created_at, log.offset)}]`,
          name: this.camMap[log.camera_id].name,
        }
      })
    },
    liveImageStyle() {
      return `background-image: url(${this.currentCamImageSrc}); background-size:contain; background-position: center;`
    },
    alertOff() {
      return this.isAlertOff(this.currentLog, this.camMap)
    },
  },
  async created() {
    await this.getCamList({ justCamList: true })
    await this.getRecentLogListv2({ inNoti: true, inRepOrg: true, inChild: false, inRespStat: false })
    this.recentLogIdSet = Util.makeRecentLogIdSet(this.recentLogs)
    const firstLog = this.recentLogs && this.recentLogs.length && this.recentLogs[0]
    await this.setImageSrc(firstLog)
    this.reqeustIntervalRecentLog()
  },
  destroyed() {
    clearTimeout(this.timeoutRecentLog)
  },
  methods: {
    ...mapActions(['getCamList', 'getLog', 'getRecentLogListv2']),
    init() {
      this.play = 'stop'
      this.currentLog = null
      this.detectionTime = '-'
      this.detectionName = '-'
      // weather info
      this.temperature = '-'
      this.windSpeed = '-'
      this.windDirection = '-'
      this.relativeHumidity = '-'
      this.station = '-'
    },
    imageLoaded() {
      this.isImageLoaded = true
    },
    async getDetailLogInfo(logId) {
      try {
        const detailLog = await this.getLog(logId)
        return detailLog
      } catch (e) {
        return null
      }
    },
    reqeustIntervalRecentLog() {
      clearTimeout(this.timeoutRecentLog)
      this.timeoutRecentLog = setTimeout(async () => {
        const firstLog = this.recentLogs && this.recentLogs.length && this.recentLogs[0]
        await this.getCamList({ justCamList: true })
        await this.getRecentLogListv2({ inNoti: true, inRepOrg: true, inChild: false, inRespStat: false })
        const updateFirstLog = this.recentLogs && this.recentLogs.length && this.recentLogs[0]
        const IS_NEWLOG = (!firstLog && updateFirstLog) || firstLog.log_id !== updateFirstLog.log_id
        const isAlreadyAlerted = this.recentLogIdSet.has(updateFirstLog.log_id)
        if (!updateFirstLog) {
          this.init()
          await this.setImageSrc(updateFirstLog)
        } else if (IS_NEWLOG && !isAlreadyAlerted && !updateFirstLog.is_noti_paused) {
          await this.setImageSrc(updateFirstLog)
          this.newLog = false
          this.$refs.audio.pause()
          this.onChangeSound(true)
          if (!this.alertOff) {
            this.alertEffect()
          }
        } else {
          await this.setImageSrc(updateFirstLog)
        }
        this.recentLogIdSet = Util.makeRecentLogIdSet(this.recentLogs)
        this.reqeustIntervalRecentLog()
      }, this.INTERVAL_SITE_LOG)
    },
    alertEffect() {
      this.$nextTick(() => {
        this.newLog = true
        this.$refs.audio.play()
        this.$nextTick(() => {
          // 만약 알림 화면이 발생되지 않았을 경우에 대한 방어코드로 재시도 한다.(이슈: VADT-298)
          if (!this.$refs.alertEffect) {
            this.alertEffect()
          }
        })
      })
    },
    async setImageSrc(log) {
      const detailLog = await this.getDetailLogInfo(log.log_id)
      if (detailLog) {
        const cam = this.camList.find((cam) => cam.id === detailLog.camera_id)
        if (cam) {
          this.currentCamImageSrc = Util.getImageUrl(cam.path, true)
          if (
            !this.currentLog ||
            this.currentLog.log_id !== detailLog.log_id ||
            (this.currentLog.log_id === detailLog.log_id && this.currentLog.direction !== detailLog.direction)
          ) {
            // this.$log.debug('Monitor@setImageSrc', this.play)
            this.play = 'stop'
            this.currentLog = detailLog
            this.$nextTick(() => {
              this.play = 'play'
            })
            this.detectionTime = Util.getOffsetDate(detailLog.created_at, detailLog.offset)
            this.detectionName = this.camMap[detailLog.camera_id].name
            this.$log.debug('setImageSrc', detailLog)
            this.temperature = detailLog.temperature || '-'
            this.windSpeed = detailLog.wind_speed || '-'
            this.windDirection = detailLog.wind_direction || '-'
            this.relativeHumidity = detailLog.relative_humidity || '-'
            this.station = detailLog.station_name || '-'
          }
        } else {
          this.init()
        }
      } else {
        this.init()
      }
    },
    onChangeSound(e) {
      this.$log.debug('Monitor#onChangeSound', e)
      this.isSound = e
      this.$refs.audio.muted = !this.isSound
      this.newLog = false // for snooze
    },
    onStopAlert() {
      this.$log.debug('Monitor#onStopAlert')
      this.$refs.audio.pause()
    },
    onStopPlayer() {
      // this.$log.debug('Monitor@onStopPlayer', this.play)
      this.play = 'stop'
      this.$nextTick(() => {
        this.play = 'play'
      })
    },
    onMousewheel(evt) {
      if (!this.isPressingControlKey) {
        this.isInvalidScroll = true
        clearTimeout(this.invalidScrollTimeout)
        this.invalidScrollTimeout = setTimeout(() => {
          this.isInvalidScroll = false
        }, 1000)
      }
    },
    onKeydown(evt) {
      if (evt.key === 'Control') {
        this.isPressingControlKey = true
      }
    },
    onKeyup(evt) {
      if (evt.key === 'Control') {
        this.isPressingControlKey = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.background {
  display: flex;
  width: 100%;
  height: 100%;
  // overflow: hidden;
  background-color: #e5e5e5;
}

.main {
  display: flex;
  width: 100%;
  height: calc(100% - 90px);
  min-height: calc(720px - 90px);
  margin-top: 90px;

  .left-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    min-width: 600px;
  }

  .right-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    min-width: 600px;
  }

  .top-container {
    display: flex;
    flex-direction: column;
    height: 50%;
  }

  .bottom-container {
    display: flex;
    flex-direction: column;
    height: 50%;
  }

  .inner-flex-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 30px 18px 10px;
  }
}

.title1 {
  display: flex;
  align-items: center;
  height: 40px;
  min-height: 36px;
  background-color: var(--f-primary-90);
  border-radius: 12px;
  font-weight: 600;
  font-size: 18px;
  color: var(--f-text-white);
  padding: 0 17px 0 20px;
  margin: 0px 10px 10px 30px;

  &.detection-clip {
    display: flex;
    justify-content: space-between;
  }

  &.livestream {
    margin: 0px 30px 10px 10px;
  }
}

.monitor-camera-provider {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.title1-map {
  font-size: 14px;
  font-weight: 500;
  color: var(--f-text-white-high);

  &.direction {
    font-weight: 600;
    font-size: 16px;
    color: var(--f-supplementary-highlight);
    margin-right: 10px;
    user-select: text;
  }
}

.player {
  position: relative;
  width: calc(100% - 40px);
  height: calc(100% - 64px);
  margin: 0 10px 18px 30px;
  background-color: var(--f-bg-middle);
}

.image-container {
  flex-grow: 1;
  width: calc(100% - 40px);
  height: calc(100% - 64px);
  background-color: var(--f-bg-middle);
  margin: 0 10px 20px 30px;

  &.right {
    margin: 0 30px 20px 10px;
  }

  &.map {
    position: relative;
    background-color: transparent;
  }

  &.background-black {
    background-color: var(--f-bg-middle);
  }

  .map-visible {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }

  .map-invisible {
    visibility: hidden;
  }
}

.info-container {
  display: flex;
  width: 100%;
  height: 0;
  flex-grow: 0.38;
  min-height: 107px;
  margin-bottom: 10px;
  border-radius: 15px;
  line-height: 27px;
  font-weight: 600;
  font-size: 20px;
  color: var(--f-text-black);
  background-color: var(--f-light-gray-1);
  &.dark {
    background-color: var(--f-bg-middle);
    color: var(--f-text-white-high);
  }

  .text-title {
    color: var(--f-primary-50);
    margin-bottom: 10px;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 0;
    padding: 0 20px;

    &.left {
      flex-grow: calc(260 / 600);
    }

    &.right {
      flex-grow: calc(340 / 600);
    }
  }

  .line-vertical-center {
    width: 1px;
    height: 100%;
    background-color: var(--f-light-gray-2);
    &.dark {
      background-color: var(--f-bg-low);
    }
  }
}

.weather-container {
  width: 100%;
  height: 0;
  flex-grow: 0.3;
  min-width: 90px;
  border-radius: 15px;
  font-weight: 500;
  background-color: var(--f-light-gray-1);
  margin-bottom: 10px;
  &.dark {
    background-color: var(--f-bg-middle);
  }
}

.recent-container {
  width: 100%;
  height: 0;
  flex-grow: 0.32;
  border-radius: 15px;
  font-weight: 500;
  min-height: 90px;
  background-color: var(--f-light-gray-1);
  &.dark {
    background-color: var(--f-bg-middle);
  }

  &.no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    color: var(--f-text-black-middle);
    &.dark {
      color: var(--f-text-white-middle);
    }
  }

  .recent-item {
    align-items: center;
    padding: 0 30px;
    width: 100%;
    height: 33.3333333%;
    // min-height: 30px;
    line-height: 19px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    color: var(--f-text-black);
    border-bottom: 1px solid var(--f-light-gray-2);
    &.dark {
      border-bottom: 1px solid var(--f-bg-high);
      color: var(--f-text-white-high);
    }

    &:last-child {
      border-bottom-width: 0;
    }

    .time {
      margin-right: 50px;
    }
  }
}

.text-selectable {
  user-select: text;
}

.map-view-scroll-guide {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 22px;
  font-weight: 400;
  transition-property: opacity;
  transition-duration: 0.8s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
  &.visible {
    opacity: 1;
  }
  &.hidden {
    opacity: 0;
  }
}
</style>
