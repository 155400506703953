<template>
  <div :class="`weather-info ${themeName}`">
    <div class="weather-top-down-container">
      <div :class="`weather-label ${themeName}`">Temperature</div>
      <div :class="`value ${themeName}`">{{ temperature }}</div>
    </div>
    <div :class="`split-line ${themeName}`"></div>
    <div class="weather-top-down-container">
      <div :class="`weather-label ${themeName}`">Wind Speed</div>
      <div :class="`value ${themeName}`">{{ windSpeed }}</div>
    </div>
    <div :class="`split-line ${themeName}`"></div>
    <div class="weather-top-down-container">
      <div :class="`weather-label ${themeName}`">Wind Direction</div>
      <div :class="`value ${themeName}`">{{ windDirection }}</div>
    </div>
    <div :class="`split-line ${themeName}`"></div>
    <div class="weather-top-down-container">
      <div :class="`weather-label ${themeName}`">Relative Humidity</div>
      <div :class="`value ${themeName}`">{{ relativeHumidity }}</div>
    </div>
    <div :class="`split-line ${themeName}`"></div>
    <div class="weather-top-down-container">
      <div :class="`weather-label ${themeName}`">Station</div>
      <div :class="`value station ${themeName}`" v-bind="attrs" v-on="on">{{ station }}</div>
    </div>
  </div>
</template>

<script>
import Theme from '@/mixin/theme.mixin'
import { mapState, mapActions } from 'vuex'
import Tooltip from '@components/desktop/superadminOrWorker/alert/Tooltip'

export default {
  mixins: [Theme],
  components: { Tooltip },
  props: {
    temperature: {
      type: String,
    },
    windSpeed: {
      type: String,
    },
    windDirection: {
      type: String,
    },
    relativeHumidity: {
      type: String,
    },
    station: {
      type: String,
    },
  },
  computed: {
    // ...mapState(['currentLog']),
    // temperature() {
    //   if (this.currentLog && this.currentLog.temperature) {
    //     return this.currentLog.temperature
    //   }
    //   return '-'
    // },
    // windSpeed() {
    //   if (this.currentLog && this.currentLog.wind_speed) {
    //     return this.currentLog.wind_speed
    //   }
    //   return '-'
    // },
    // windDirection() {
    //   if (this.currentLog && this.currentLog.wind_direction) {
    //     return this.currentLog.wind_direction
    //   }
    //   return '-'
    // },
    // station() {
    //   if (this.currentLog && this.currentLog.station_name) {
    //     return this.currentLog.station_name
    //   }
    //   return '-'
    // },
  },
}
</script>

<style lang="scss" scoped>
.weather-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 69px;
  border-radius: 16px;
  // padding: 0 30px;
  font-weight: 600;
  font-size: 16px;
  background-color: var(--f-light-gray-1);
  &.dark {
    background-color: var(--f-bg-middle);
  }

  .weather-top-down-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    height: 100%;
    width: 25%;
  }
  .weather-label {
    font-size: 14px;
    font-weight: 500;
    color: var(--f-text-gray);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    white-space: nowrap;
    &.dark {
      color: var(--f-text-white-high);
    }
  }

  .value {
    font-weight: 600;
    height: 50%;
    color: var(--f-text-black);
    display: flex;
    justify-content: center;
    text-align: center;
    &.station {
      font-size: 12px;
    }
    &.dark {
      color: var(--f-primary-50);
    }
  }
}

.split-line {
  width: 1px;
  height: 50%;
  // margin: 0 60px;
  background-color: var(--f-light-gray-2);
  &.dark {
    background-color: var(--f-bg-high);
  }
}
</style>
